import { ApiHandler } from "@/lib/ApiHandler";
import { groupService } from "@/services/groupService";

class UserService extends ApiHandler {
  groupService = null;

  constructor(_groupService) {
    super("/api/users");
    this.groupService = _groupService;
  }

  async list(filters = null) {
    return await  this.get("", filters)
      .then(res => res.json())
      .then(res => res.result);
  }

  async paginatedList(filters = null) {
    if (filters?.exclude == undefined | null){
      return await this.get("", filters)
      .then(res => res.json())
      .then(res => {
        return {
          result: res.result,
          meta: res.meta
        };
      });
    } else {
      return this.post("getall", filters)
      .then(res => res.json())
      .then(res => {
        return {
          result: res.result,
          meta: res.meta
        };
      });
    }

  }

  async export(filters = null) {
    return await this.get("export", filters)
      .then(res => res.json())
      .then(res => res.result);
  }

  async import(body) {
    return await this.post("import", body)
    .then(res => res.json())
    .then(res => res.result);
   }

  async speakers() {
    const groups = await this.groupService.list();
    const speaker = groups.filter(group => group.name === "Speaker");
    return await this.get(`?idGroup=${speaker[0].id}&perPage=-1`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async readLogs(id) {
    return await this.get(`${id}/logs`)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async resetUserPassword(id, body) {
    return await this.put(`${id}/reset-user-password`, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async generateAutoLogin(id) {
    return await this.patch(`${id}/generate-autologin`)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async exportAutologinCodes(body) {
    return await this.post("export-autologin-codes", body);
  }

  async setStatus(id, status) {
    return await this.patch(`${id}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async refreshVerificationToken(id) {
    return await this.patch(`${id}/refresh-verification-token`)
      .then(res => res.json())
      .then(res => res);
  }


  async sendCompleteRegistrationEmail(id) {
    return await this.patch(`${id}/sendCompleteRegistrationEmail`)
      .then(res => res.json())
      .then(res => res);
  }

  async setStatusMultiple(idList, status) {
    return await this.patch(`multiple/${status}`, idList)
      .then(res => res.json())
      .then(res => res);
  }

  async setVisitorLevelMultiple(body) {
    return await this.post("multiple/add-visitor-level", body)
      .then(res => res.json())
      .then(res => res);
  }

  async removeVisitorLevelMultiple(body) {
    return await this.post("multiple/remove-visitor-level", body)
      .then(res => res.json())
      .then(res => res);
  }

  async generateMultipleAutologinCode(body) {
    return await this.post("multiple/generate-autologin-code", body)
      .then(res => res.json())
      .then(res => res);
  }

  async updateGroup(idUser, body) {
    return await this.patch(`${idUser}/updateGroup`, body)
      .then(res => res.json())
      .then(res => res);
  }
}

export const userService = new UserService(groupService);